.sectionHeroTop {
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 1200px) {
  .sectionHeroTop {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop {
    max-width: 992px;
    max-height: 75vh;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop {
    max-width: 768px;
    max-height: 75vh;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop {
    max-width: 576px;
    max-height: 25vh;
  }
}

.sectionHeroTop__title {
  margin-top: 160px;
  margin-bottom: 20px;
  font-size: 28px;
  color: #ffffff85;
}

@media (max-width: 1200px) {
  .sectionHeroTop__title {
    font-size: 28px;
    margin-top: 240px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop__title {
    font-size: 24px;
    margin-top: 140px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop__title {
    font-size: 22px;
    margin-top: 140px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop__title {
    font-size: 14px;
    margin-top: 170px;
    margin-bottom: 10px;
  }
}

@media (max-width: 376px) {
  .sectionHeroTop__title {
    font-size: 14px;
    margin-top: 115px;
    margin-bottom: 10px;
  }
}

.sectionHeroTop__subtitle {
  font-size: 56px;
  font-weight: 700;
  color: #fc051ead;
  padding-bottom: 350px;
  letter-spacing: 1px;
}

@media (max-width: 1200px) {
  .sectionHeroTop__subtitle {
    font-size: 44px;
    padding-bottom: 350px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop__subtitle {
    font-size: 33px;
    padding-bottom: 75px;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop__subtitle {
    font-size: 24px;
    padding-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop__subtitle {
    font-size: 14px;
    padding-bottom: 0px;
  }
}

.sectionHeroTop__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.sectionHeroTop__content {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 30px 15px;
}
