@import url(https://fonts.googleapis.com/css2?family=Merriweather&display=swap);
body {
  margin: 0;
  overflow-x: hidden;
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  overflow-x: hidden;
}
  

.header {
  padding: 10px 15px 0px 15px;
}

.header__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1140px;
}

@media (max-width: 1200px) {
  .header__navbar {
    max-width: 970px;
  }
}

@media (max-width: 992px) {
  .header__navbar {
    max-width: 750px;
  }
}

@media (max-width: 768px) {
  .header__navbar {
    max-width: 578px;
    display: none;
  }
}

@media (max-width: 576px) {
  .header__navbar {
    max-width: none;
  }
}

.header__item {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.header__item img {
  max-width: 125px;
}

.header__item:hover {
  cursor: pointer;
  text-decoration: none;
  color: #737773;
  transition: 0.1s;
}

.header__item:active {
  color: #fc051d;
  transition: 0.1s;
}

.header__mobile-toggle {
  display: none;
  position: fixed;
  top: 82px;
  right: 44px;
  z-index: 50;
}

@media (max-width: 768px) {
  .header__mobile-toggle {
    display: block;
  }
}

.header__mobile-toggle ul, .header__mobile-toggle input {
  display: none;
}

.header__mobile-toggle label {
  cursor: pointer;
  color: #fff;
  font-size: 36px;
}

.header__mobile-toggle ul {
  position: absolute;
  right: 31px;
  top: 40px;
  padding: 25px 30px;
  border-radius: 15px 0px 15px 15px;
  list-style: none;
  background: #22262aeb;
}

.header__mobile-toggle ul li {
  margin: 20px 0px;
}

.header__mobile-toggle Link {
  text-decoration: none;
  font-size: 18px;
  font-family: Poppins-Regular;
  color: #E7F5F8;
  text-transform: uppercase;
}

.header__mobile-toggle Link:hover {
  cursor: pointer;
}

.header__mobile-toggle :checked ~ ul {
  display: inline-block;
}

.header__logo-mobile {
  display: none;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .header__logo-mobile {
    display: block;
  }
}

.header__logo-mobile img {
  width: 100px;
}

.sectionHeroTop {
  text-align: center;
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

@media (max-width: 1200px) {
  .sectionHeroTop {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop {
    max-width: 992px;
    max-height: 75vh;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop {
    max-width: 768px;
    max-height: 75vh;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop {
    max-width: 576px;
    max-height: 25vh;
  }
}

.sectionHeroTop__title {
  margin-top: 160px;
  margin-bottom: 20px;
  font-size: 28px;
  color: #ffffff85;
}

@media (max-width: 1200px) {
  .sectionHeroTop__title {
    font-size: 28px;
    margin-top: 240px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop__title {
    font-size: 24px;
    margin-top: 140px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop__title {
    font-size: 22px;
    margin-top: 140px;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop__title {
    font-size: 14px;
    margin-top: 170px;
    margin-bottom: 10px;
  }
}

@media (max-width: 376px) {
  .sectionHeroTop__title {
    font-size: 14px;
    margin-top: 115px;
    margin-bottom: 10px;
  }
}

.sectionHeroTop__subtitle {
  font-size: 56px;
  font-weight: 700;
  color: #fc051ead;
  padding-bottom: 350px;
  letter-spacing: 1px;
}

@media (max-width: 1200px) {
  .sectionHeroTop__subtitle {
    font-size: 44px;
    padding-bottom: 350px;
  }
}

@media (max-width: 992px) {
  .sectionHeroTop__subtitle {
    font-size: 33px;
    padding-bottom: 75px;
  }
}

@media (max-width: 768px) {
  .sectionHeroTop__subtitle {
    font-size: 24px;
    padding-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .sectionHeroTop__subtitle {
    font-size: 14px;
    padding-bottom: 0px;
  }
}

.sectionHeroTop__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sectionHeroTop__content {
  position: relative;
  z-index: 2;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.616);
  padding: 30px 15px;
}

.sectionHeroBottom {
  position: relative;
  text-align: center;
  background: url(/static/media/bg_sectionHeroBottom.1c505b42.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 15px 0px 15px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .sectionHeroBottom {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom {
    max-width: 576px;
  }
}

.sectionHeroBottom__pretitle {
  margin-top: 100px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 14px;
  }
}

.sectionHeroBottom__title {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 3px;
  padding-bottom: 50px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__title {
    font-size: 38px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__title {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__title {
    font-size: 24px;
  }
}

.sectionHeroBottom__text-top {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__text-top {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__text-top {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__text-top {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__text-top {
    font-size: 12px;
  }
}

.sectionHeroBottom__text-bottom {
  color: #fff;
  padding-bottom: 350px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__text-bottom {
    font-size: 18px;
    padding-bottom: 350px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__text-bottom {
    font-size: 16px;
    padding-bottom: 300px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__text-bottom {
    font-size: 14px;
    padding-bottom: 250px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__text-bottom {
    font-size: 12px;
    padding-bottom: 100px;
  }
}

.photoGallery {
  max-width: 1140px;
  margin: 0 auto;
  padding: 30px 15px 0px 15px;
}

.PhotoGalleryText {
  margin-top: 50px;
  background: url(/static/media/bg_photoGalleryText.83dce901.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 15px 0px 15px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText {
    max-width: 576px;
  }
}

.PhotoGalleryText__box {
  padding-top: 425px;
  padding-bottom: 400px;
  max-width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__box {
    max-width: 970px;
    padding-top: 225px;
    padding-bottom: 200px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__box {
    max-width: 750px;
    padding-top: 200px;
    padding-bottom: 175px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__box {
    max-width: 578px;
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__box {
    padding-top: 125px;
    padding-bottom: 75px;
  }
}

.PhotoGalleryText__title {
  color: #fc051d;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 5px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__title {
    font-size: 32px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__title {
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__title {
    font-size: 24px;
  }
}

.PhotoGalleryText__item {
  color: #fff;
  font-size: 24px;
  letter-spacing: 3px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__item {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__item {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__item {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__item {
    font-size: 14px;
  }
}

.price {
  margin-top: 50px;
}

.price__box {
  background: url(/static/media/bg_price.39bd0eff.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 0px 0px 15px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) {
  .price__box {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .price__box {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .price__box {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .price__box {
    max-width: 576px;
  }
}

.price__title {
  margin: 150px 0px 0px 185px;
}

@media (max-width: 1200px) {
  .price__title {
    margin: 120px 0px 0px 100px;
  }
}

@media (max-width: 992px) {
  .price__title {
    margin: 95px 0px 0px 0px;
  }
}

@media (max-width: 768px) {
  .price__title {
    margin: 65px 0px 0px 60px;
  }
}

@media (max-width: 576px) {
  .price__title {
    margin: 40px 0px 0px 0px;
  }
}

.price__title h2 {
  font-size: 48px;
  color: #b8000d;
  font-weight: 800;
  letter-spacing: 5px;
}

@media (max-width: 1200px) {
  .price__title h2 {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .price__title h2 {
    font-size: 34px;
  }
}

@media (max-width: 768px) {
  .price__title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .price__title h2 {
    font-size: 28px;
  }
}

.price__services {
  margin-top: 150px;
  margin-bottom: 175px;
}

@media (max-width: 1200px) {
  .price__services {
    margin-top: 125px;
    margin-bottom: 150px;
  }
}

@media (max-width: 992px) {
  .price__services {
    margin-top: 75px;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .price__services {
    margin-top: 50px;
    margin-bottom: 75px;
  }
}

@media (max-width: 576px) {
  .price__services {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

.price__item {
  padding-right: 120px;
  padding-left: 10px;
  margin-bottom: 25px;
  font-size: 32px;
  background-color: #1a1a1a;
  border-left: 7px solid #fc051d;
}

@media (max-width: 1200px) {
  .price__item {
    font-size: 32px;
    padding-left: 8px;
    padding-right: 80px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .price__item {
    font-size: 24px;
    padding-left: 6px;
    padding-right: 50px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .price__item {
    font-size: 20px;
    padding-left: 4px;
    padding-right: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .price__item {
    font-size: 16px;
    padding-left: 2px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
}

.price__item span {
  display: block;
  padding-left: 20px;
  color: #fff;
}

@media (max-width: 1200px) {
  .price__item span {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .price__item span {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .price__item span {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .price__item span {
    font-size: 12px;
  }
}

.price__item_price {
  font-size: 24px;
  font-weight: 800;
}

.main {
  max-width: 1600px;
  margin: 0 auto;
}

.contacts {
  background: url(/static/media/bg_contacts.f019d61f.png) 50% 0 no-repeat;
  background-size: cover;
}

.contacts__container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 130px 15px 0px 15px;
}

@media (max-width: 1200px) {
  .contacts__container {
    max-width: 970px;
    padding-top: 130px;
  }
}

@media (max-width: 992px) {
  .contacts__container {
    max-width: 750px;
    padding-top: 100px;
  }
}

@media (max-width: 768px) {
  .contacts__container {
    max-width: 578px;
    padding-top: 80px;
  }
}

@media (max-width: 576px) {
  .contacts__container {
    max-width: none;
    padding-top: 40px;
  }
}

.contacts__title {
  text-align: center;
  font-size: 38px;
  color: #ff0018;
  font-weight: 800;
  letter-spacing: 5px;
  padding-bottom: 70px;
}

@media (max-width: 1200px) {
  .contacts__title {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .contacts__title {
    font-size: 34px;
  }
}

@media (max-width: 768px) {
  .contacts__title {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .contacts__title {
    font-size: 28px;
  }
}

.contacts__box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 55px;
}

@media (max-width: 1200px) {
  .contacts__box {
    padding-bottom: 45px;
  }
}

@media (max-width: 992px) {
  .contacts__box {
    padding-bottom: 35px;
  }
}

@media (max-width: 768px) {
  .contacts__box {
    padding-bottom: 25px;
  }
}

@media (max-width: 576px) {
  .contacts__box {
    padding-bottom: 15px;
  }
}

.contacts__logo img {
  margin-bottom: 30px;
  max-width: 80px;
}

@media (max-width: 1200px) {
  .contacts__logo img {
    margin-bottom: 30px;
    max-width: 100%;
  }
}

@media (max-width: 992px) {
  .contacts__logo img {
    margin-bottom: 20px;
    max-width: 120px;
  }
}

@media (max-width: 768px) {
  .contacts__logo img {
    margin-bottom: 15px;
    max-width: 100px;
  }
}

@media (max-width: 576px) {
  .contacts__logo img {
    margin-bottom: 10px;
    max-width: 60px;
  }
}

.contacts__logo img:hover {
  cursor: pointer;
}

.contacts__logo p {
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .contacts__logo p {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .contacts__logo p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .contacts__logo p {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .contacts__logo p {
    font-size: 8px;
  }
}

.contacts__social-networks {
  display: flex;
}

.social-networks__item {
  margin-right: 20px;
}

.social-networks__item i {
  font-size: 24px;
  color: #fff;
}

@media (max-width: 1200px) {
  .social-networks__item i {
    font-size: 18px;
    margin-right: 18px;
  }
}

@media (max-width: 992px) {
  .social-networks__item i {
    font-size: 12px;
    margin-right: 16px;
  }
}

.social-networks__item i:hover {
  cursor: pointer;
  color: #737773;
  transition: 0.1s;
}

.social-networks__item i:active {
  color: #fc051d;
  transition: 0.1s;
}

.address__address {
  font-size: 18px;
  color: #fff;
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .address__address {
    font-size: 18px;
    margin-bottom: 26px;
  }
}

@media (max-width: 992px) {
  .address__address {
    font-size: 12px;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .address__address {
    font-size: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .address__address {
    font-size: 8px;
    margin-bottom: 16px;
  }
}

.address__tel {
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .address__tel {
    margin-bottom: 26px;
  }
}

@media (max-width: 992px) {
  .address__tel {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .address__tel {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .address__tel {
    margin-bottom: 16px;
  }
}

.address__tel a {
  text-decoration: none;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .address__tel a {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .address__tel a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .address__tel a {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .address__tel a {
    font-size: 8px;
  }
}

.address__tel a:hover {
  cursor: pointer;
  color: #737773;
  transition: 0.1s;
}

.address__tel a:active {
  color: #fc051d;
  transition: 0.1s;
}

.address__mail {
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .address__mail {
    margin-bottom: 26px;
  }
}

@media (max-width: 992px) {
  .address__mail {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .address__mail {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .address__mail {
    margin-bottom: 16px;
  }
}

.address__mail a {
  text-decoration: none;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .address__mail a {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .address__mail a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .address__mail a {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .address__mail a {
    font-size: 8px;
  }
}

.address__mail a:hover {
  cursor: pointer;
  color: #737773;
  transition: 0.1s;
}

.address__mail a:active {
  color: #fc051d;
  transition: 0.1s;
}

.address__site {
  margin-bottom: 30px;
}

@media (max-width: 1200px) {
  .address__site {
    margin-bottom: 26px;
  }
}

@media (max-width: 992px) {
  .address__site {
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .address__site {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .address__site {
    margin-bottom: 16px;
  }
}

.address__site a {
  text-decoration: none;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .address__site a {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .address__site a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .address__site a {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .address__site a {
    font-size: 8px;
  }
}

.address__site a:hover {
  cursor: pointer;
  color: #737773;
  transition: 0.1s;
}

.address__site a:active {
  color: #fc051d;
  transition: 0.1s;
}

.timetable__title {
  margin-bottom: 30px;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .timetable__title {
    font-size: 18px;
    margin-bottom: 26px;
  }
}

@media (max-width: 992px) {
  .timetable__title {
    font-size: 12px;
    margin-bottom: 24px;
  }
}

@media (max-width: 768px) {
  .timetable__title {
    font-size: 10px;
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .timetable__title {
    font-size: 8px;
    margin-bottom: 16px;
  }
}

.timetable__day {
  margin-bottom: 5px;
  font-size: 18px;
  color: #fff;
}

@media (max-width: 1200px) {
  .timetable__day {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .timetable__day {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .timetable__day {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .timetable__day {
    font-size: 8px;
  }
}

.mapAddress {
  margin: 0 auto;
  text-align: center;
}

.mapAddress iframe {
  width: 1600px;
  height: 150px;
  border: none;
}

@media (max-width: 1200px) {
  .mapAddress iframe {
    width: 1990px;
  }
}

@media (max-width: 992px) {
  .mapAddress iframe {
    width: 990px;
  }
}

@media (max-width: 768px) {
  .mapAddress iframe {
    width: 753px;
  }
}

@media (max-width: 576px) {
  .mapAddress iframe {
    width: 400px;
  }
}

.navigationRigth__up {
  text-align: end;
  margin-top: -57px;
  margin-right: 45px;
  position: fixed;
  bottom: 30px;
  right: 15px;
  z-index: 100;
}

@media (max-width: 768px) {
  .navigationRigth__up {
    display: none;
  }
}

.navigationRigth__link {
  color: #ff0018;
  font-size: 38px;
}

.navigationRigth__link:hover {
  cursor: pointer;
  color: #737773;
  transition: 0.1s;
}

.navigationRigth__link:active {
  color: #ff0018;
  transition: 0.1s;
}

.footer {
  background-color: #b8000d;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0px 15px 0px 15px;
}

@media (max-width: 1200px) {
  .footer {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .footer {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .footer {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .footer {
    max-width: 576px;
  }
}

.footer__text {
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: -7px;
}

@media (max-width: 1200px) {
  .footer__text {
    max-width: 970px;
  }
}

@media (max-width: 992px) {
  .footer__text {
    max-width: 750px;
  }
}

@media (max-width: 768px) {
  .footer__text {
    max-width: 578px;
  }
}

@media (max-width: 576px) {
  .footer__text {
    max-width: none;
  }
}

.footer__monolit {
  color: #fff;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .footer__monolit {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .footer__monolit {
    font-size: 12px;
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .footer__monolit {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .footer__monolit {
    font-size: 8px;
    padding-top: 18px;
  }
}

.footer__creator {
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer__creator a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer__creator a {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .footer__creator a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .footer__creator a {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .footer__creator a {
    font-size: 8px;
  }
}

.banner {
  max-width: 1600px;
  margin: 0 auto;
  background-image: linear-gradient(to right, #0066ff, #f3ef00);
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 1200px) {
  .banner {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .banner {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .banner {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .banner {
    max-width: none;
  }
}

.banner a img {
  width: 300px;
}

@media (max-width: 1200px) {
  .banner a img {
    width: 250px;
  }
}

@media (max-width: 992px) {
  .banner a img {
    width: 200px;
  }
}

@media (max-width: 768px) {
  .banner a img {
    width: 150px;
  }
}

@media (max-width: 576px) {
  .banner a img {
    width: 100px;
  }
}

.banner-text {
  font-size: 28px;
  font-weight: 600;
  color: #fff;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

@media (max-width: 1200px) {
  .banner-text {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .banner-text {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .banner-text {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .banner-text {
    font-size: 9px;
  }
}

@media (max-width: 380px) {
  .banner-text {
    font-size: 7px;
  }
}

.banner-link {
  text-decoration: none;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  color: #fff;
  padding: 10px 15px;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #0066ff;
}

@media (max-width: 1200px) {
  .banner-link {
    font-size: 16px;
    padding: 8px 12px;
  }
}

@media (max-width: 992px) {
  .banner-link {
    font-size: 14px;
    padding: 6px 9px;
  }
}

@media (max-width: 768px) {
  .banner-link {
    font-size: 12px;
    padding: 4px 6px;
  }
}

@media (max-width: 576px) {
  .banner-link {
    font-size: 10px;
    padding: 2px 4px;
  }
}

@media (max-width: 380px) {
  .banner-link {
    font-size: 5px;
  }
}

.banner-link:hover {
  text-decoration: none;
  color: #0066ff;
  background-color: #f3ef00;
  transition: 0.3s;
}

