.sectionHeroBottom {
  position: relative;
  text-align: center;
  background: url(./../sources/images/bg_sectionHeroBottom.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 15px 0px 15px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .sectionHeroBottom {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom {
    max-width: 576px;
  }
}

.sectionHeroBottom__pretitle {
  margin-top: 100px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__pretitle {
    margin-top: 100px;
    font-size: 14px;
  }
}

.sectionHeroBottom__title {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 3px;
  padding-bottom: 50px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__title {
    font-size: 38px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__title {
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__title {
    font-size: 28px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__title {
    font-size: 24px;
  }
}

.sectionHeroBottom__text-top {
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__text-top {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__text-top {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__text-top {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__text-top {
    font-size: 12px;
  }
}

.sectionHeroBottom__text-bottom {
  color: #fff;
  padding-bottom: 350px;
}

@media (max-width: 1200px) {
  .sectionHeroBottom__text-bottom {
    font-size: 18px;
    padding-bottom: 350px;
  }
}

@media (max-width: 992px) {
  .sectionHeroBottom__text-bottom {
    font-size: 16px;
    padding-bottom: 300px;
  }
}

@media (max-width: 768px) {
  .sectionHeroBottom__text-bottom {
    font-size: 14px;
    padding-bottom: 250px;
  }
}

@media (max-width: 576px) {
  .sectionHeroBottom__text-bottom {
    font-size: 12px;
    padding-bottom: 100px;
  }
}
