.PhotoGalleryText {
  margin-top: 50px;
  background: url(../sources/images/bg_photoGalleryText.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 15px 0px 15px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText {
    max-width: 576px;
  }
}

.PhotoGalleryText__box {
  padding-top: 425px;
  padding-bottom: 400px;
  max-width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__box {
    max-width: 970px;
    padding-top: 225px;
    padding-bottom: 200px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__box {
    max-width: 750px;
    padding-top: 200px;
    padding-bottom: 175px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__box {
    max-width: 578px;
    padding-top: 150px;
    padding-bottom: 100px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__box {
    padding-top: 125px;
    padding-bottom: 75px;
  }
}

.PhotoGalleryText__title {
  color: #fc051d;
  font-size: 42px;
  font-weight: 800;
  letter-spacing: 5px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__title {
    font-size: 32px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__title {
    font-size: 26px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__title {
    font-size: 24px;
  }
}

.PhotoGalleryText__item {
  color: #fff;
  font-size: 24px;
  letter-spacing: 3px;
}

@media (max-width: 1200px) {
  .PhotoGalleryText__item {
    font-size: 22px;
  }
}

@media (max-width: 992px) {
  .PhotoGalleryText__item {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .PhotoGalleryText__item {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .PhotoGalleryText__item {
    font-size: 14px;
  }
}
