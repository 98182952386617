.price {
  margin-top: 50px;
}

.price__box {
  background: url(../sources/images/bg_price.png) 50% 0 no-repeat;
  background-size: cover;
  padding: 0px 0px 0px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

@media (max-width: 1200px) {
  .price__box {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .price__box {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .price__box {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .price__box {
    max-width: 576px;
  }
}

.price__title {
  margin: 150px 0px 0px 185px;
}

@media (max-width: 1200px) {
  .price__title {
    margin: 120px 0px 0px 100px;
  }
}

@media (max-width: 992px) {
  .price__title {
    margin: 95px 0px 0px 0px;
  }
}

@media (max-width: 768px) {
  .price__title {
    margin: 65px 0px 0px 60px;
  }
}

@media (max-width: 576px) {
  .price__title {
    margin: 40px 0px 0px 0px;
  }
}

.price__title h2 {
  font-size: 48px;
  color: #b8000d;
  font-weight: 800;
  letter-spacing: 5px;
}

@media (max-width: 1200px) {
  .price__title h2 {
    font-size: 40px;
  }
}

@media (max-width: 992px) {
  .price__title h2 {
    font-size: 34px;
  }
}

@media (max-width: 768px) {
  .price__title h2 {
    font-size: 32px;
  }
}

@media (max-width: 576px) {
  .price__title h2 {
    font-size: 28px;
  }
}

.price__services {
  margin-top: 150px;
  margin-bottom: 175px;
}

@media (max-width: 1200px) {
  .price__services {
    margin-top: 125px;
    margin-bottom: 150px;
  }
}

@media (max-width: 992px) {
  .price__services {
    margin-top: 75px;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .price__services {
    margin-top: 50px;
    margin-bottom: 75px;
  }
}

@media (max-width: 576px) {
  .price__services {
    margin-top: 10px;
    margin-bottom: 25px;
  }
}

.price__item {
  padding-right: 120px;
  padding-left: 10px;
  margin-bottom: 25px;
  font-size: 32px;
  background-color: #1a1a1a;
  border-left: 7px solid #fc051d;
}

@media (max-width: 1200px) {
  .price__item {
    font-size: 32px;
    padding-left: 8px;
    padding-right: 80px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .price__item {
    font-size: 24px;
    padding-left: 6px;
    padding-right: 50px;
    margin-bottom: 15px;
  }
}

@media (max-width: 768px) {
  .price__item {
    font-size: 20px;
    padding-left: 4px;
    padding-right: 20px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .price__item {
    font-size: 16px;
    padding-left: 2px;
    padding-right: 5px;
    margin-bottom: 5px;
  }
}

.price__item span {
  display: block;
  padding-left: 20px;
  color: #fff;
}

@media (max-width: 1200px) {
  .price__item span {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .price__item span {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .price__item span {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .price__item span {
    font-size: 12px;
  }
}

.price__item_price {
  font-size: 24px;
  font-weight: 800;
}
