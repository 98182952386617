.footer {
  background-color: #b8000d;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0px 15px 0px 15px;
}

@media (max-width: 1200px) {
  .footer {
    max-width: 1200px;
  }
}

@media (max-width: 992px) {
  .footer {
    max-width: 992px;
  }
}

@media (max-width: 768px) {
  .footer {
    max-width: 768px;
  }
}

@media (max-width: 576px) {
  .footer {
    max-width: 576px;
  }
}

.footer__text {
  max-width: 1140px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto;
  margin-top: -7px;
}

@media (max-width: 1200px) {
  .footer__text {
    max-width: 970px;
  }
}

@media (max-width: 992px) {
  .footer__text {
    max-width: 750px;
  }
}

@media (max-width: 768px) {
  .footer__text {
    max-width: 578px;
  }
}

@media (max-width: 576px) {
  .footer__text {
    max-width: none;
  }
}

.footer__monolit {
  color: #fff;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .footer__monolit {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .footer__monolit {
    font-size: 12px;
    padding-top: 15px;
  }
}

@media (max-width: 768px) {
  .footer__monolit {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .footer__monolit {
    font-size: 8px;
    padding-top: 18px;
  }
}

.footer__creator {
  padding-top: 10px;
  padding-bottom: 10px;
}

.footer__creator a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .footer__creator a {
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .footer__creator a {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .footer__creator a {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .footer__creator a {
    font-size: 8px;
  }
}
