.header {
  padding: 10px 15px 0px 15px;
}

.header__navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  max-width: 1140px;
}

@media (max-width: 1200px) {
  .header__navbar {
    max-width: 970px;
  }
}

@media (max-width: 992px) {
  .header__navbar {
    max-width: 750px;
  }
}

@media (max-width: 768px) {
  .header__navbar {
    max-width: 578px;
    display: none;
  }
}

@media (max-width: 576px) {
  .header__navbar {
    max-width: none;
  }
}

.header__item {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.header__item img {
  max-width: 125px;
}

.header__item:hover {
  cursor: pointer;
  text-decoration: none;
  color: #737773;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.header__item:active {
  color: #fc051d;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.header__mobile-toggle {
  display: none;
  position: fixed;
  top: 82px;
  right: 44px;
  z-index: 50;
}

@media (max-width: 768px) {
  .header__mobile-toggle {
    display: block;
  }
}

.header__mobile-toggle ul, .header__mobile-toggle input {
  display: none;
}

.header__mobile-toggle label {
  cursor: pointer;
  color: #fff;
  font-size: 36px;
}

.header__mobile-toggle ul {
  position: absolute;
  right: 31px;
  top: 40px;
  padding: 25px 30px;
  border-radius: 15px 0px 15px 15px;
  list-style: none;
  background: #22262aeb;
}

.header__mobile-toggle ul li {
  margin: 20px 0px;
}

.header__mobile-toggle Link {
  text-decoration: none;
  font-size: 18px;
  font-family: Poppins-Regular;
  color: #E7F5F8;
  text-transform: uppercase;
}

.header__mobile-toggle Link:hover {
  cursor: pointer;
}

.header__mobile-toggle :checked ~ ul {
  display: inline-block;
}

.header__logo-mobile {
  display: none;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .header__logo-mobile {
    display: block;
  }
}

.header__logo-mobile img {
  width: 100px;
}
